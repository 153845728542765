import React from 'react';
import './App.css';
import {BrowserRouter,Switch,Route} from "react-router-dom";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import References from "./References/References";
import NotFound from "./404/NotFound";
import NavigationBar from "./NavigationBar/NavigationBar";
import {Container} from "react-bootstrap";
import Jumbo from "./Jumbo/Jumbo";

class App extends React.Component{

  render(){
    return (
      <React.Fragment>
        <BrowserRouter>
          <NavigationBar/>          
          <Container>          
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/mk" component={Home}/>
                <Route exact path="/bg" component={Home}/>
                <Route exact path="/uk" component={Home}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/references" component={References}/>
                <Route exact component={NotFound}/> 
              </Switch>
            </Container>
          </BrowserRouter>       
      </React.Fragment>
      )
  }
}

export default App;
