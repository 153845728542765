import React from 'react';
import {Container,Row,Col,Image,Carousel,CarouselItem} from "react-bootstrap";
import "./Home.css";

class Home extends React.Component{
    render(){
        return (
            <React.Fragment>
                <br/>
                <Carousel>
                    <Carousel.Item>
                        <Image
                        className="images"
                        src={require("./dam.jpg")}
                        rounded/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                        className="images"
                        src={require("./windmill.jpg")}
                        rounded/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                        className="images"
                        src={require("./dam2.jpg")}
                        rounded/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                        className="images"
                        src={require("./tram.jpg")}
                        rounded/>
                    </Carousel.Item>
                </Carousel>
                <br/>
                    <p>
                        <b>Johnson & Phillips Ltd.</b> has been established in 1995 in Skopje, Republic of North Macedonia with main activities of the Manufacturer’s Representation & Trading Arm of Johnson & Phillips 
                        group of companies. Our activity strong point is in providing consultancy  
                        services and logistic solutions for special projects such as electric power generation plants, overhead transmission lines, grid stations, 
                        renewable energy sources, water supply treatment plants, irrigation equipment and waste water treatment, traffic infrastructure projects and import and export activity.
                        We are  representing group of Macedonian manufacturing and civil construction companies for execution of projects abroad of Republic of North Macedonia. A good example of the special 
                        technology product that we promote at China would be the Railway wagon brakes from WABTEC MZT Ltd. based in Skopje, North Macedonia. These railway brakes are installed in a number of 
                        the railways around the world.
                        J&P North Macedonia is affiliated with the Johnson & Phillips Group. Our sister companies span the globe, and are involved in manufacturing and trading a wide spectrum of goods.                
                    </p>                             
                <p className="text-center"><b>Johnson & Phillips Group - since 1875.</b><br/>Bulgaria - Canada - China - North Macedonia - UAE - UK </p>
            </React.Fragment>
        )
    }
}

export default Home;