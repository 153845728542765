import React from 'react';

class References extends React.Component{
    render(){
        return <React.Fragment>
            <h2>References</h2>
            <p>
                1. Consultancy and export logistic services to WABTEC MZT, Skopje, North Macedonia of railways wagons brakes to Meishan Rolling Stock Works (MRS) China,  and others. In period of 2001-2013 it been dispatched 2.500 air brake sets in approx total value of 8.500.000 USD. Collaboration continuing.
                <br/>
                <br/>
                2. Consultancy services to BBV Trade S.A. and GUP- Bilbao, Spain for turn key project, water intake tower electro mechanical equipment of Lisice dam project at city of Veles, North Macedonia. Period of execution from 2005-2008 under mixed FAD soft loan financing by Spanish credit institution ICO in amount of 9.8 MIL. Eur.
                <br/>
                <br/>
                3. Consultancy services to ISINEL (consortium of  Isolux +Inabensa + Elecnor SA) , Madrid, Spain of turn key project Hydro-power plant LERA at public irrigation enterprise Strezevo- Bitola, North Macedonia. Period of execution is 2 years under FAD soft  loan financed  by Spanish credit institution ICO  in amount of 7,3 MIL. Eur. Project delayed in start of  execution.
                <br/>
                <br/>
                4. Consultancy services for Skopje tram design supervision project to IDOM- Bilbao, Spain executed in period November 2008-Mart 2009 of estimated value 60.000 Eur.
                <br/>
                <br/>
                5. Consultancy services to Sigma Motors Ltd.- Karachi and export activities for manufacturing of tools for wheel discs  in North Macedonia for Pk version of LR Defender. Project started in May 2008 and finalized  in next 3 yeras by continual export of wheel discs.
                <br/>
                <br/>
                6. Consultancy services to IDOM,  Bilbao, Spain for Basic Study for the traffic system of city of Skopje with preliminary project for underground road for central square for the client Ministry of transport and communications of North Macedonia. This project started in 2009 with estimated value of 350.000 Eur and finalized by 2011.
                <br/>
                <br/>
                7. Consultancy services to IDOM,  Bilbao, Spain for Feasibility  Study for public private partnership of amusement park LUNA at city of Skopje with preparation of tender doc. and contract for concession for the client Skopje city authorities. This project started at 2011 with estimated value of 300.000 Eur and finalized by 2012.
                <br/>
                <br/>
                8.Consultancy services to ABENGOA - Inabensa, Seville for construction of 400 KW Transmission Line from Leskovac, Serbia to the border of North Macedonia, approx 200 km length. This project started in 2011 estimated value 12 millon EUR financed by EU funds and finalized by 2014. 
                <br/>
                <br/>
                9. Consultancy services to IDOM,  Bilbao, Spain for Detailed Design documentation  with traffic solution and BOQ for underground tunnel of 980 m length via central area of city of Skopje for the client Skopje city authorities. This project started in 2012 with estimated value of  800.000 Eur and finalized by 2013.
                <br/>
                <br/>
                10. Consultancy services to consortium leaded by IDOM, Bilbao, Spain and partners ADT Omega, Greece, ZPD, Zagreb, Croatia for detailed design of railway line interconnection North Macedonia-Bulgaria, section 3 Kriva Palanka to the border with Bulgaria of 28 km length consisting of more than 30 tunnels and bridges. Detailed design project value 3.5 million Eur, started January 2015 and finalized in December 2017.                                    
            </p>
            </React.Fragment>
    }
}

export default References;