import React from 'react';
import {Navbar,Nav,NavLink} from 'react-bootstrap';
import "./NavigationBar.css";
import {Link} from "react-router-dom";

const link = "http://investnorthmacedonia.gov.mk/";

class NavigationBar extends React.Component{

    render(){
      return (
          <Navbar id="navigation" expand="lg" bg="dark" variant="dark">
            <Navbar.Brand>
              <img
                src={require("./logo.png")}
                width="150"
                height="80"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <span id="company-name">Johnson & Phillips Ltd</span>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link ><Link className="links" to="/">Home</Link></Nav.Link>               
                <Nav.Link ><Link className="links" to="/references">References</Link></Nav.Link>             
                <Nav.Link className="links" href={link} target="_blank">Invest in North Macedonia</Nav.Link>
                <Nav.Link ><Link className="links" to="/contact">Contact</Link></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )
    }
  }
  
  export default NavigationBar;