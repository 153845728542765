import React from 'react';
import "./Contact.css";
import {Form,Button} from "react-bootstrap";
import emailjs from "emailjs-com";
import Alert from 'react-bootstrap/Alert'

//https://dashboard.emailjs.com
const templateId = 'template_icjd6Lfo';
const serviceID="gmail";
const userId="user_xHrsADWffijpTGarSIogc";

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state={
            email:"",
            msg:"",
            visible:false
        }
        this.handleEmailChange=this.handleEmailChange.bind(this);
        this.handleMsgChange=this.handleMsgChange.bind(this);
        this.handleSend=this.handleSend.bind(this);
    }

    handleEmailChange(e){
        this.setState({email:e.target.value})
    }

    handleMsgChange(e){
        this.setState({msg:e.target.value})
    }

    handleSend(e){
        e.preventDefault();        
        this.sendFeedback(templateId, {message_html: this.state.msg, from_name: this.state.email, reply_to: this.state.email})
    }

    sendFeedback (templateId, variables) {
        emailjs.send(
            serviceID, templateId,
          variables,
          userId
          ).then(res => {                      
            console.log('Email successfully sent!');
            //todo add alert
            this.onShowAlert();

            //resest fields
            this.setState({
                visible:true,
                email:"",
                msg:""
            })
          }).catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }

    //ES6 arrow function
    onShowAlert = () =>{
        this.setState({visible:true},()=>{
          window.setTimeout(()=>{
            this.setState({visible:false})
          },2000)
        });
    }

    sendMsgInfo(){
        return (
            <Form id="sendMsg" onSubmit={this.handleSend}>
                <h2>Send us a message</h2>      
                <Alert variant='success' show={this.state.visible}>Email sent!</Alert>                    
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Your Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required id="email" value={this.state.email} onChange={this.handleEmailChange}/>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows="3" required id="msg" value={this.state.msg} onChange={this.handleMsgChange}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Send Message
                </Button>
            </Form>       
        );
    }

    contactInfo(){
        return (
            <div id="contact">
                <h2>Contact</h2>
                <p>
                <i>Central European Office:</i>
                <br/>
                <b>Goran Arnaudovski</b> 
                <br/>                      
                General Manager  
                <br/>                                                   
                <br/>
                Johnson & Phillips Ltd. North Macedonia  
                <br/>   
                “Dame Gruev” No. 16-3/12, 1000 Skopje  
                <br/>                                  
                Republic of North Macedonia
                <br/>
                <br/>
                Tel.: + (389 2) 3131 360; +389 70 235 458
                <br/>
                e-mail: jnpmk@johnson-phillips.com.mk
                </p>   
            </div>
        );
    }

    
    render(){
        return <React.Fragment>
                <div id="div-flex">                                       
                    {this.contactInfo()}
                    {this.sendMsgInfo()}
                </div>
            </React.Fragment>
    }
}

export default Contact;