import React from 'react';
import {Jumbotron,Container} from 'react-bootstrap';
import "./Jumbo.css"

class Jumbo extends React.Component{
    render(){
        return (
            <Jumbotron fluid/>
        )
    }
}

export default Jumbo;